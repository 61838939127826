<template>
  <v-container
    id="configuration"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-3">
              Configuration
            </div>
          </template>
          <v-form>
            <v-tabs
              vertical
              v-model="tabs"
            >
              <v-tab
                :to="'#infos-societe'"
              >
                <v-icon left>
                  mdi-domain
                </v-icon>
                Infos société
              </v-tab>
              <v-tab
                :to="'#infos-organisme'"
              >
                <v-icon left>
                  mdi-school
                </v-icon>
                Infos organisme
              </v-tab>
              <v-tab
                :to="'#infos-bancaires'"
              >
                <v-icon left>
                  mdi-bank
                </v-icon>
                Infos bancaires
              </v-tab>
              <v-tab
                :to="'#documents'"
              >
                <v-icon left>
                  mdi-text-box-multiple-outline
                </v-icon>
                Documents
              </v-tab>
              <v-tab
                :to="'#valeurs-par-defaut'"
              >
                <v-icon left>
                  mdi-cogs
                </v-icon>
                Valeurs par défaut
              </v-tab>
              <v-tab
                :to="'#utilisateurs'"
              >
                <v-icon left>
                  mdi-account-group
                </v-icon>
                Utilisateurs
              </v-tab>
              <v-tab-item
                :key="0"
                value="infos-societe"
              >
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <h4>Informations légales de la société</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.raisonSociale"
                          label="Raison sociale"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.nomCommercial"
                          label="Nom commercial"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.siren"
                          label="SIREN"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.siret"
                          label="SIRET"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.noTvaIntra"
                          label="N° TVA intracommunautaire"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.codeApe"
                          label="Code APE"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.capitalSocial"
                          label="Capital social"
                          suffix="€"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.villeRcs"
                          label="Ville RCS"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="7"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.tribunal"
                          label="Tribunal compétent"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <h4>Coordonnées de la société</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Adresse"
                          v-model="config.adressePostale"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Complément"
                          v-model="config.adresseComplement"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Bâtiment"
                          v-model="config.adresseBatiment"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="1"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Étage"
                          v-model="config.adresseEtage"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="4"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Code postal"
                          v-model="config.adresseCodePostal"
                          :rules="[v => !!v || 'Indiquez le code postal']"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="4"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Ville"
                          v-model="config.adresseVille"
                          :rules="[v => !!v || 'Indiquez la ville']"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="4"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Pays"
                          v-model="config.adressePays"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="N° de téléphone"
                          v-model="config.telephone"
                          type="tel"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Adresse e-mail"
                          v-model="config.email"
                          type="email"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Site web"
                          v-model="config.siteWeb"
                          type="url"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <h4>Responsable légal de la société</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-select
                          outlined
                          hide-details
                          v-model="config.respLegalTitre"
                          :items="titreItems"
                          item-text="titre"
                          item-value="sexe"
                          label="Titre"
                          single-line
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Nom"
                          v-model="config.respLegalNom"
                          :rules="[v => !!v || 'Indiquez le nom']"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          label="Prénom"
                          v-model="config.respLegalPrenom"
                          :rules="[v => !!v || 'Indiquez le prénom']"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                      <v-file-input
                          outlined
                          hide-details
                          id="input-signature"
                          v-model="signature"
                          accept="image/*"
                          label="Signature / tampon"
                          prepend-icon="mdi-camera"
                          :style="config.signature && !signature ? 'display: none;' : ''"
                        />
                        <v-card
                          v-if="config.signature && !signature"
                          class="ma-0"
                          elevation="1"
                        >
                            <v-card-title
                              class="v-label theme--light"
                            >
                              Signature / tampon
                            </v-card-title>
                            <v-card-text
                              class=""
                            >
                              <img
                                :src="displaySignature"
                                class="image-resized"
                              />
                            </v-card-text>
                            <label
                              for="input-signature"
                              class="remplacer-image"
                            >
                              <div>
                                <v-icon color="white">mdi-file-replace</v-icon>
                                Remplacer l'image
                              </div>
                            </label>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <h4>Identité visuelle de la société</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-file-input
                          outlined
                          hide-details
                          id="input-logo"
                          v-model="logo"
                          accept="image/*"
                          label="Logo"
                          prepend-icon="mdi-camera"
                          :style="config.logo && !logo ? 'display: none;' : ''"
                        />
                        <v-card
                          v-if="config.logo && !logo"
                          class="ma-0"
                          elevation="1"
                        >
                            <v-card-title
                              class="v-label theme--light"
                            >
                              Logo
                            </v-card-title>
                            <v-card-text
                              class=""
                            >
                              <img
                                :src="displayLogo"
                                class="image-resized"
                              />
                            </v-card-text>
                            <label
                              for="input-logo"
                              class="remplacer-image"
                            >
                              <div>
                                <v-icon color="white">mdi-file-replace</v-icon>
                                Remplacer l'image
                              </div>
                            </label>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="1"
                value="infos-organisme"
              >
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.nda"
                          label="N° de déclaration d'activité"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.ndaRegion"
                          label="Préfet de région NDA"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="3"
                      >
                        <v-switch
                          v-model="config.qualiopi"
                          label="Certificat Qualiopi"
                        />
                      </v-col>
                      <v-col
                        v-if="config.qualiopi"
                        cols="6"
                        md="4"
                      >
                        <v-menu
                          ref="menuQualiopiDateObtention"
                          v-model="menuQualiopiDateObtention"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                          hide-details
                              v-model="computedQualiopiDateObtention"
                              label="Date d'obtention Qualiopi"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              required
                            />
                          </template>
                          <v-date-picker
                            class="mt-0"
                            v-model="config.qualiopiDateObtention"
                            :first-day-of-week="1"
                            locale="fr"
                            @input="menuQualiopiDateObtention = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        v-if="config.qualiopi"
                        cols="6"
                        md="4"
                      >
                        <v-menu
                          ref="menuQualiopiDateExpiration"
                          v-model="menuQualiopiDateExpiration"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                          hide-details
                              v-model="computedQualiopiDateExpiration"
                              label="Date d'expiration Qualiopi"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              required
                            />
                          </template>
                          <v-date-picker
                            class="mt-0"
                            v-model="config.qualiopiDateExpiration"
                            :first-day-of-week="1"
                            locale="fr"
                            @input="menuQualiopiDateExpiration = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                value="infos-bancaires"
              >
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.agenceBancaire"
                          label="Agence bancaire"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.iban"
                          label="IBAN"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.swift"
                          label="SWIFT"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          outlined
                          hide-details
                          v-model="config.tauxTvaDefaut"
                          label="Taux TVA par défaut"
                          class="purple-input align-right"
                          type="number"
                          suffix="%"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="3"
                value="documents"
              >
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <h4>Réglage du footer</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-textarea
                          outlined
                          ref="footerDocs"
                          v-model="config.footerDocs"
                        />
                        <v-chip
                          v-for="chip in footerDocsChips"
                          :key="chip.value"
                          class="ma-1 footer-docs-chips"
                          small
                          @click="addChipToFooter(chip.value)"
                        >
                          {{ chip.name }}
                        </v-chip>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <h5>Aperçu :</h5>
                        <div
                          class="footer-apercu"
                          v-html="apercuFooter()"
                        >
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <h4>Mentions supplémentaires sur le footer des factures</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-textarea
                          outlined
                          label=""
                          v-model="config.footerFactures"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="4"
                value="valeurs-par-defaut"
              >
                tab valeurs par défaut
              </v-tab-item>
              <v-tab-item
                :key="5"
                value="utilisateurs"
              >
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="users"
                    />
                    <div
                      class="d-flex justify-end mb-3"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        disabled
                      >{{ $t('add-user') }}</v-btn>
                  </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
            <v-container>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="primary"
                    @click="submitForm"
                  >
                    Mettre à jour
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { mapMutations } from 'vuex';

  export default {
    name: 'Configuration',

    data() {
      return {
        menuQualiopiDateObtention: null,
        menuQualiopiDateExpiration: null,
        tabs: 0,
        items: [],
        headers: [
          {
            sortable: true,
            text: 'Nom',
            value: 'lastName',
          },
          {
            sortable: true,
            text: 'Prénom',
            value: 'firstName',
          },
          {
            sortable: false,
            text: 'Email',
            value: 'username',
          },
          {
            sortable: true,
            text: 'Rôle',
            value: 'role',
          },
        ],
        users: [],
        config: {},
        logo: null,
        displayLogo: null,
        signature: null,
        displaySignature: null,
        titreItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
        footerDocsChips: [
          {
            name: 'Nom du document',
            value: 'nomDocument',
          },
          {
            name: 'Raison sociale',
            value: 'raisonSociale',
          },
          {
            name: 'Nom commercial',
            value: 'nomCommercial',
          },
          {
            name: 'Civilité responsable légal',
            value: 'respLegalTitre',
          },
          {
            name: 'Nom responsable légal',
            value: 'respLegalNom',
          },
          {
            name: 'Prénom responsable légal',
            value: 'respLegalPrenom',
          },
          {
            name: 'SIREN',
            value: 'siren',
          },
          {
            name: 'SIRET',
            value: 'siret',
          },
          {
            name: 'Ville RCS',
            value: 'villeRcs',
          },
          {
            name: 'N° TVA Intra',
            value: 'noTvaIntra',
          },
          {
            name: 'Code APE',
            value: 'codeApe',
          },
          {
            name: 'Capital social',
            value: 'capitalSocial',
          },
          {
            name: 'Adresse e-mail',
            value: 'email',
          },
          {
            name: 'N° de téléphone',
            value: 'telephone',
          },
          {
            name: 'Site web',
            value: 'siteWeb',
          },
          {
            name: 'N° de déclaration d\'activité',
            value: 'nda',
          },
          {
            name: 'Préfet de région NDA',
            value: 'ndaRegion',
          },
          {
            name: 'Adresse postale',
            value: 'adressePostale',
          },
          {
            name: 'Complément d\'adresse',
            value: 'adresseComplement',
          },
          {
            name: 'Bâtiment',
            value: 'adresseBatiment',
          },
          {
            name: 'Étage',
            value: 'adresseEtage',
          },
          {
            name: 'Code postal',
            value: 'adresseCodePostal',
          },
          {
            name: 'Ville',
            value: 'adresseVille',
          },
          {
            name: 'Pays',
            value: 'adressePays',
          },
          {
            name: 'Agence bancaire',
            value: 'agenceBancaire',
          },
          {
            name: 'IBAN',
            value: 'iban',
          },
          {
            name: 'SWIFT',
            value: 'swift',
          },
        ],
        footerFacturesChips: [
          {
            name: 'Agence bancaire',
            value: 'agenceBancaire',
          },
          {
            name: 'IBAN',
            value: 'iban',
          },
          {
            name: 'SWIFT',
            value: 'swift',
          },
        ],
      }
    },

    created() {
      this.axios.get('/configuration')
        .then((res) => {
          this.items = res.data;
          const obj = {};
          for (const key of this.items.map(item => item.key)) {
            obj[key] = this.items.find(c => c.key === key).value;
          }
          this.config = obj;

          if (this.config.logo) {
            this.axios.get('/configuration/getImage/logo', { responseType: 'arraybuffer' })
              .then(res => {
                this.displayLogo = 'data:image/png;base64,' + Buffer.from(res.data, 'binary').toString('base64');
              })
          }

          if (this.config.signature) {
            this.axios.get('/configuration/getImage/signature', { responseType: 'arraybuffer' })
              .then(res => {
                this.displaySignature = 'data:image/png;base64,' + Buffer.from(res.data, 'binary').toString('base64');
              })
          }
        });

      this.axios.get('/users').then((res) => {
        this.users = res.data;
      });
    },

    computed: {
      computedQualiopiDateObtention() {
        return this.formatDate(this.config.qualiopiDateObtention)
      },
      computedQualiopiDateExpiration() {
        return this.formatDate(this.config.qualiopiDateExpiration)
      },
    },

    methods: {
      submitForm() {
        let formData;

        this.axios.put('/configuration', this.config)
          .then(res => {
            if (this.logo) {
              formData = new FormData();
              formData.append('file', this.logo);
              return this.axios.patch('/configuration/uploadImage/logo', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            if (this.signature) {
              formData = new FormData();
              formData.append('file', this.signature);
              return this.axios.patch('/configuration/uploadImage/signature', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then(res => {
            this.setSnack({ text: 'La configuration a bien été mise à jour', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la modification de la configuration', type: 'warn' });
          })
      },

      addChipToFooter(value) {
        this.config.footerDocs += '[' + value + ']';
        this.$refs.footerDocs.focus();
      },

      apercuFooter() {
        /* eslint dot-notation: 0 */

        const text = 'Page 1 sur 3<br>' + this.config.footerDocs
          .replace('[nomDocument]', 'Questionnaire de satisfaction')
          .replace(/\[(.*?)\]/g, (match, key) => this.config[key] || `<span class="footer-config-not-found">${match}</span>`)
          .replace(/\n/g, '<br>')
        return text;
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  .image-resized {
    max-width: 100%;
    max-height: 70px;
  }

  .remplacer-image {
    opacity: 0;
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #cacaca80;
    text-shadow: 0px 0px 7px #000000;
    border-radius: inherit;
    font-size: 1.1em;
    cursor: pointer;
    border: dashed 3px #fff;
    transition: 0.2s;
  }

  .remplacer-image:hover {
    opacity: 1;
    transition: 0.2s;
  }

  .remplacer-image > div {
    margin: auto;
    color: #fff;
  }

  #configuration >>> .v-tab {
    text-transform: none;
    justify-content: left;
    letter-spacing: normal;
    padding-right: 50px;
  }

  #configuration >>> .v-tabs-items .v-card {
    margin-top: 0;
  }

  #configuration >>> .v-tabs-items .v-window-item {
    padding: 0 20px;
  }

  #configuration >>> .footer-docs-chips {
    cursor: pointer;
  }

  #configuration >>> .footer-apercu {
    margin: 8px 0;
    font-size: 0.9rem;
    text-align: center;
    color: gray;
  }

  #configuration >>> .footer-config-not-found {
    border-bottom: dashed 1px red;
  }
</style>
